import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import AddManager from "../views/AddManager.vue";
import RateManager from "../views/RateManager.vue";
import AdminPage from "../views/AdminPage.vue";
import ManagerDetail from "../components/ManagerDetail.vue";
import AboutPage from "../views/AboutPage.vue";
import Managers from "../views/ManagersPage.vue";
import Companies from "../views/CompaniesPage.vue";
import HomePage from "../views/Home_page.vue"

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/manager/:managerId",
    name: "ManagerDetail",
    component: ManagerDetail,
    props: true,
  },
  {
    path: "/add-manager",
    name: "AddManager",
    component: AddManager,
  },
  {
    path: "/rate-manager",
    name: "RateManager",
    component: RateManager,
  },
  {
    path: "/admin",
    name: "AdminPage",
    component: AdminPage,
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/companies",
    name: "CompaniesPage",
    component: Companies,
  },
  {
    path: "/managers",
    name: "ManagersPage",
    component: Managers,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
