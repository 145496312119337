<template>
  <div class="companies-section">
    <h2 class="section-title">Top Companies</h2>

    <div class="best-of">
      <div class="highlight-card">
        <h3>Best of the Month</h3>
        <p class="highlight">Facebook <span class="rating">(3.7)</span></p>
      </div>
      <div class="highlight-card">
        <h3>Best of the Year</h3>
        <p class="highlight">Amazon <span class="rating">(4.1)</span></p>
      </div>
    </div>

    <table class="companies-table">
      <thead>
        <tr>
          <th>Company</th>
          <th># of Rated Managers</th>
          <th>Avg. Rating</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="company in companies" :key="company.name">
          <td>{{ company.org }}</td>
          <td>{{ company.rating_count }}</td>
          <td>{{ parseFloat(company.average_rating).toFixed(1) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import baseURL from "../../config.js";

export default {
  data() {
    return {
      bestOfMonth: {
        name: "",
        rating: null,
      },
      bestOfYear: {
        name: "",
        rating: null,
      },
      companies: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        // Fetch best of the month and year data
        // const bestOfResponse = await axios.get("/api/best-of");
        // this.bestOfMonth = bestOfResponse.data.bestOfMonth;
        // this.bestOfYear = bestOfResponse.data.bestOfYear;

        // Fetch company data
        const companiesResponse = await fetch(`${baseURL.baseUrl}/companies`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.companies = await companiesResponse.json();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped>
.companies-section {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.best-of {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.highlight-card {
  flex: 1;
  margin: 0 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.highlight-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.highlight {
  font-size: 18px;
  font-weight: bold;
}

.rating {
  color: #f39c12;
}

.companies-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.companies-table th,
.companies-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.companies-table th {
  background-color: #284051;
  color: #fff;
  font-weight: bold;
}

.companies-table td {
  background-color: #fff;
}

@media (max-width: 768px) {
  .best-of {
    flex-direction: column;
  }

  .highlight-card {
    margin-bottom: 10px;
  }
}
</style>