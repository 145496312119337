<template>
  <div class="home-page">
    <header class="hero-section">
      <h1>Welcome to the Rate My Manager</h1>
      <p>
        Rate and review managers with ease. Your feedback matters to help
        improve workplace leadership and create a better environment for all.
      </p>
      <router-link to="/home" class="cta-button"
        >Rate a Manager</router-link
      >
    </header>

    <section class="features-section">
      <h2>Why Use Our Rate My Manager App?</h2>
      <div class="features">
        <div class="feature-card">
            <div class="flex justify-center"><img src="../assets/rate-logo.png" alt="Track Feedback" /></div>
            <h3>Rate Managers</h3>
          <p>
            Share your experience by rating your managers and providing valuable
            feedback.
          </p>
        </div>
        <div class="feature-card">
          <div class="flex justify-center"><img src="../assets/Track.png" alt="Track Feedback" /></div>
          <h3>Track Feedback</h3>
          <p>
            Monitor all the ratings and reviews provided to managers in
            real-time.
          </p>
        </div>
        <div class="feature-card">
            <div class="flex justify-center"><img src="../assets/secure.png" alt="Track Feedback" /></div>
            <h3>Secure & Confidential</h3>
          <p>
            Your feedback is confidential, ensuring your privacy and promoting
            open dialogue.
          </p>
        </div>
      </div>
    </section>

    <!-- <footer class="footer-section">
      <router-link to="/" class="home-link">Back to Home</router-link>
    </footer> -->
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home-page {
  font-family: "Arial", sans-serif;
  color: #333;
}

.hero-section {
  background-image: url("../assets/background.png ");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 60px 20px;
  color: black;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #244459;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #2980b9;
}

.features-section {
  padding: 60px 20px;
  background-color: #f7f7f7;
  text-align: center;
}

.features-section h2 {
  font-size: 2rem;
  margin-bottom: 40px;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 300px;
  text-align: center;
}

.feature-card img {
  max-width: 100px;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1rem;
  color: #666;
}

.footer-section {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
}

.home-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
